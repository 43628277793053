class BusinessConstant {

    static get VEHICLE_CATEGORY_TOURISM_AND_BUSINESS() {
        return 'TourismAndBusiness';
    }

    static get VEHICLE_CATEGORY_MOBILE_HOME() {
        return 'Mobilhome';
    }

    static get VEHICLE_CATEGORY_OLD_TIMER_OR_CLASSIC_CAR() {
        return 'OldTimerOrClassicCar';
    }

    static get VEHICLE_CATEGORY_TWO_WHEELS_AND_SIMILAR() {
        return 'TwoWheelsAndsimilar';
    }

    static get VEHICLE_CATEGORY_TRAILER() {
        return 'Trailer';
    }

    static get VEHICLE_CATEGORY_OTHER_VEHICLES() {
        return 'OtherVehicles';
    }

    static get VEHICLE_CATEGORY_CAR() {
        return 'Car_PV';
    }

    static get VEHICLE_TYPE_QUAD_PV() {
        return 'Quad_PV';
    }

    static get VEHICLE_TYPE_TRICYCLE_PV() {
        return 'Tricycle_PV';
    }

    static get VEHICLE_TYPE_MOPED_PV() {
        return 'Moped_PV';
    }

    static get VEHICLE_TYPE_MOTO_PV() {
        return 'Moto_PV';
    }

    static get VEHICLE_TYPE_CLASSIC_MOTO() {
        return 'ClassicMoto_PV';
    }

    static get VEHICLE_TYPE_ELE_FUEL_TYPE() {
        return 'ELE';
    }

    static get LINE_OF_BUSINESS_CODE_PPVLINE_PV() {
        return 'PPVLine_PV';
    }

    static get VEHICLE_PRIMARY_USE_PPV() {
        return 'PPV_PV';
    }

    static get VEHICLE_PRIMARY_USE_PCV() {
        return 'PCV_PV';
    }

    static get LINE_OF_BUSINESS_CODE_PCVLINE_PV() {
        return 'PCVLine_PV';
    }

    static get VEHICLE_DRIVER_USAGE_SEC() {
        return 'SEC';
    }

    static get VEHICLE_DRIVER_USAGE_NDR() {
        return 'NDR';
    }

    static get VEHICLE_DRIVER_USAGE_PRI() {
        return 'PRI';
    }

    static IS_VEHICLE_DRIVER_USAGE_SEC(vehicleDriver) {
        return vehicleDriver?.vehicleDriverUsage?.value === this.VEHICLE_DRIVER_USAGE_SEC;
    }

    static get PAYMENT_FREQUENCY_MONTHLY_PLAN() {
        return 'monthly';
    }

    static get PAYMENT_FREQUENCY_EVERY_YEAR_PLAN() {
        return 'everyyear';
    }

    static get SIGNATURE_STATUS_PENDING() {
        return 'pending';
    }

    static get SIGNATURE_STATUS_SIGNED() {
        return 'signed';
    }

    static get SIGNATURE_STATUS_DECLINED() {
        return 'declined';
    }

    static get PAYMENT_STATUS_FAILED() {
        return 'failed';
    }

    static get PAYMENT_STATUS_PENDING() {
        return 'pending';
    }

    static get PAYMENT_STATUS_APPROVED() {
        return 'approved';
    }

    static get SELECTED_PLAN_EVERYYEAR() {
        return 'everyyear';
    }

    static get SELECTED_PLAN_EVERYSIXMONTHS() {
        return 'everysixmonths';
    }

    static get SELECTED_PLAN_QUARTERLY() {
        return 'quarterly';
    }

    static get PAYMENT_TYPE_ONLINE() {
        return 'online_bancomat_payment';
    }

    static get PAYMENT_TYPE_DEFERRED() {
        return 'deferred_payment';
    }

    static get PAYMENT_TYPE_NO_ONLINE() {
        return 'no_online_payment';
    }

    static get SIGN_METHOD_PAPER() {
        return 'paper_signature';
    }

    static get SIGN_METHOD_DEFERRED() {
        return 'electronic_deferred';
    }

    static get SIGN_METHOD_ELECTRONIC() {
        return 'electronic_online';
    }

    static get BILLING_METHOD_PRODUCER_BILL_DEFAULT_PRODUCER() {
        return 'producer_bill_default_pv';
    }

    static get BILLING_METHOD_PRODUCER_BILL_FOR_THIS_POLICY() {
        return 'producer_bill_policy_pv';
    }

    static get BILLING_METHOD_PRODUCER_BILL_MANDATORY_DEFAULT_PRODUCER() {
        return 'producer_bill_man_default_pv';
    }

    static get BILLING_METHOD_PRODUCER_BILL_MANDATORY_FOR_THIS_POLICY() {
        return 'producer_bill_mandatory_policy_pv';
    }

    static get BILLING_METHOD_MIXED_BILL_DEFAULT_PRODUCER() {
        return 'mixed_bill_default_pv';
    }

    static get BILLING_METHOD_MIXED_BILL_FOR_THIS_POLICY() {
        return 'mixed_bill_policy_pv';
    }

    static get BILLING_METHOD_DIRECT_BILL_DEFAULT_FOR_THIS_POLICY() {
        return 'direct_bill_man_default_pv';
    }

    static get BILLING_METHOD_DIRECT_BILL_DEFAULT_PRODUCER() {
        return 'direct_bill_default_pv';
    }

    static get BILLING_METHOD_DIRECT_BILL_FOR_THIS_POLICY() {
        return 'direct_bill_policy_pv';
    }

    static get ADDRESS() {
        return 'Address';
    }

    static get HOME() {
        return 'home';
    }

    static get MODE_TILE() {
        return 'tile';
    }

    static get MODE_FULL() {
        return 'full';
    }

    static get MAXIMUM_PERIOD_START_DAY() {
        return 7;
    }

    static get ANTI_THEFT_CODE_OTHER() {
        return 'Other';
    }

    static get FILTERED_LIST_FOR_TNB() {
        return 'FilteredListForTandB';
    }

    static get ANTI_THEFT_SYSTEM_TYPELIST() {
        return 'PPVAntiTheftSystem_PV';
    }

    static get PCV_ANTI_THEFT_SYSTEM_TYPELIST() {
        return 'PCVAntiTheftSystem_PV';
    }

    static get WEB_DIV_URL() {
        return 'https://www.inmotiv.be/nl/home/webdiv';
    }

    static get BOUND_STATUS() {
        return 'Bound';
    }

    static get PPV_QUOTED_STATE() {
        return 'PPVDone';
    }

    static get PCV_QUOTED_STATE() {
        return 'PCVDone';
    }

    static get PPV_THIRD_PARTY_LIABILITY_COV_PV() {
        return 'PPVThirdPartyLiabilityCovPv';
    }

    static get PPV_LEGAL_PROTECTION_COV_PV() {
        return 'PPVLegalProtectionCovPv';
    }

    static get PCV_THIRD_PARTY_LIABILITY_COV_PV() {
        return 'PCVThirdPartyLiabilityCovPvCV';
    }

    static get PCV_LEGAL_PROTECTION_COV_PV() {
        return 'PCVLegalProtectionCovPvCV';
    }

    static get BRB_PV() {
        return 'BRB_PV';
    }

    static get DRQ_PV() {
        return 'DRQ_PV';
    }

    static get DIRECTBILL_PV() {
        return 'Directbill';
    }

    static get PRODUCERBILL_PV() {
        return 'Producerbill';
    }

    static get AGENCY_PV() {
        return 'agency';
    }

    static get NONEMPLOYEES_PV() {
        return 'nonEmployees';
    }

    static get PRIMARY_USE_FOODTRUCK() {
        return 'FoodTruck_PV';
    }

    static get PRIMARY_USE_MARKET_VEHICLE() {
        return 'MarketVehicle_PV';
    }

    static get PRIMARY_USE_TRANSPORT_ACCOUNT_OF_OTHERS() {
        return 'TransportAccOfOthers_PV';
    }

    static get PRIMARY_USE_TRANSPORT_OWN_ACCOUNT() {
        return 'TransportOwnAccount_PV';
    }

    static get PRIMARY_USE_TOWING() {
        return 'TowingVehicle_PV';
    }

    static get PRIMARY_USE_COURTESY_VEHICLE() {
        return 'CourtesyVehicle_PV';
    }

    static get COVERAGE_PUBLICID_PPV_MATERIAL_DAMAGE() {
        return 'PPVMaterialDamageCovPv';
    }

    static get COVERAGE_PUBLICID_PCV_MATERIAL_DAMAGE() {
        return 'PCVMaterialDamageCovPvCV';
    }

    static get COVERAGE_TERM_PATTERNCODE_PPV_MATERIAL_DAMAGE() {
        return 'PPVMaterialDamageCovPvDeductibleLevel';
    }

    static get COVERAGE_TERM_PATTERNCODE_PCV_MATERIAL_DAMAGE_QQ() {
        return 'zkugia7nfbdhl98nprusn4eevg8';
    }

    static get COVERAGE_TERM_DROPDOWN_PATTERNCODE_PPV_MATERIAL_DAMAGE() {
        return 'PPVMaterialDamageCovPvDeductibleDropdown';
    }

    static get COVERAGE_TERM_DROPDOWN_PATTERNCODE_PCV_MATERIAL_DAMAGE() {
        return 'z0jh0krlv4j00693jro9teteusa';
    }

    static get COVERAGE_TERM_PATTERNCODE_PPV_MATERIAL_DAMAGE_DEDUCTIBLE() {
        return 'PPVMaterialDamageCovPvDeductible';
    }

    static get COVERAGE_TERM_PATTERNCODE_PCV_MATERIAL_DAMAGE_DEDUCTIBLE() {
        return 'z6eh417vft7h39ge4pbkh7fmocb';
    }

    static get MATERIAL_DAMAGE_DEDUCTIBLE_HIGH_PPV() {
        return 'zlmgqddaolg851ce2jf140becg9';
    }

    static get MATERIAL_DAMAGE_DEDUCTIBLE_LOW_PPV() {
        return 'z6pj2n1ks7pl39bna7ojqjajct9';
    }

    static get MATERIAL_DAMAGE_DEDUCTIBLE_MEDIUM_PPV() {
        return 'zs0huc972te782qvbg21r4autg8';
    }

    static get MATERIAL_DAMAGE_DEDUCTIBLE_ENGLISH_PPV() {
        return 'zipi2t832h0vl00a7nnj9cbg868';
    }

    static get MATERIAL_DAMAGE_DEDUCTIBLE_HIGH_PCV() {
        return 'zc5iaie2tds391tguitr2r31r5a';
    }

    static get MATERIAL_DAMAGE_DEDUCTIBLE_LOW_PCV() {
        return 'zk1ii4sor29a8a2pkm9v4ebcupa';
    }

    static get MATERIAL_DAMAGE_DEDUCTIBLE_MEDIUM_PCV() {
        return 'zm8gk8hlr36ueebgbno8t11epp9';
    }

    static get MATERIAL_DAMAGE_DEDUCTIBLE_ENGLISH_PCV() {
        return 'zlag2kvjjg3km94pdds23birk88';
    }

    static get COVERAGE_PUBLICID_PPV_THEFT() {
        return 'PPVTheftCovPv';
    }

    static get COVERAGE_TERM_PATTERNCODE_PPV_THEFT() {
        return 'PPVTheftCovPvDeductibleDropdown';
    }


    static get COVERAGE_TERM_PATTERNCODE_PCV_MATERIAL_DAMAGE() {
        return 'z0jh0krlv4j00693jro9teteusa';
    }

    static get COVERAGE_PUBLICID_PCV_THEFT() {
        return 'PCVTheftCovPvCV';
    }

    static get COVERAGE_TERM_PATTERNCODE_PCV_THEFT() {
        return 'zisisma994q20bpb02lt4d0fpa8';
    }

    static get MY_VEHICLE_SYSTEM_COVERAGE_PCV() {
        return 'PCVMyVehicleSystemCovPv';
    }

    static get MY_VEHICLE_COMP_FORMULA_COV_PCV() {
        return 'PCVMyVehCompFormulaCovPv';
    }

    static get MY_PASSENGERS_AND_OTHERS_COV_PCV() {
        return 'PCVMyPassengersTheOthersAndIGrp';
    }

    static get NET_PREMIUM_COST_PV() {
        return 'netPremiumCost';
    }

    static get SPREAD_COST_PV() {
        return 'spreadCost';
    }

    static get TAXES_COST_PV() {
        return 'taxesCost';
    }

    static get TOTAL_PREMIUM_PV() {
        return 'totalPremium';
    }

    static get ACQUISITION_COST_PV() {
        return 'acquisitionCost';
    }

    static get ADMIN_COST_PV() {
        return 'adminCost';
    }

    static get PCV_THIRD_PARTY_LIABILITY_COV_PV_DEDUCTIBLE() {
        return 'PCVThirdPartyLiabilityCovPvDeductible';
    }

    static get PCV_THIRD_PARTY_LIABILITY_COVERAGE_PV() {
        return 'PCVThirdPartyLiabilityCovPv';
    }

    static get TAX_REGIME_NORMAL_TAXATION() {
        return 'normal_tax';
    }

    static get POL_CHNG_REASON_CHANGE_VEHICLE() {
        return 'change_vehicles';
    }

    static get POL_CHNG_REASON_CHANGE_DRIVER() {
        return 'change_drivers';
    }

    static get POL_CHNG_REASON_CHANGE_COVERAGE() {
        return 'change_coverages';
    }

    static get POL_CHNG_REASON_CHANGE_PAYMENT_DETAILS() {
        return 'change_payment_details_pv';
    }

    static get POL_CHNG_REASON_CHANGE_VIN_AND_LICENSEPLATE() {
        return 'vin_licenseplate_change';
    }

    static get POL_CHNG_VEHICLES() {
        return 'vehicles';
    }

    static get POL_CHNG_DRIVERS() {
        return 'drivers';
    }

    static get POL_CHNG_COVERAGES() {
        return 'coverages';
    }

    static get POL_CHNG_PAYMENTS() {
        return 'payments';
    }

    static get POL_CHNG_VIN_LICENSEPLATE() {
        return 'vin_licenseplate';
    }

    static get PPV_LOB_CODE() {
        return 'ppvPersonalAuto_PV';
    }

    static get PCV_LOB_CODE() {
        return 'pcvCommercialAuto_PV';
    }

    static get PPV_PROD_CODE() {
        return 'PersonalVehicle_PV';
    }

    static get PCV_PROD_CODE() {
        return 'CommercialVehicle_PV';
    }

    static get PV_BRAND_VIVIUM() {
        return 'vivium';
    }

    static get BASE_TRANSACTION_WIZARD() {
        return 'baseTransactionWizard';
    }

    static get BillingMethodListForProducerBill() {
        return [BusinessConstant.BILLING_METHOD_PRODUCER_BILL_DEFAULT_PRODUCER,
            BusinessConstant.BILLING_METHOD_PRODUCER_BILL_MANDATORY_DEFAULT_PRODUCER,
            BusinessConstant.BILLING_METHOD_PRODUCER_BILL_FOR_THIS_POLICY,
            BusinessConstant.BILLING_METHOD_PRODUCER_BILL_MANDATORY_FOR_THIS_POLICY];
    }

    static get BillingMethodListForDirectBill() {
        return [BusinessConstant.BILLING_METHOD_DIRECT_BILL_FOR_THIS_POLICY,
            BusinessConstant.BILLING_METHOD_DIRECT_BILL_DEFAULT_FOR_THIS_POLICY,
            BusinessConstant.BILLING_METHOD_DIRECT_BILL_DEFAULT_PRODUCER];
    }

    static get BillingMethodListForMixedBill() {
        return [BusinessConstant.BILLING_METHOD_MIXED_BILL_DEFAULT_PRODUCER,
            BusinessConstant.BILLING_METHOD_MIXED_BILL_FOR_THIS_POLICY];
    }

    static get CreateListCollectionMethodsWithoutOnlinePayment() {
        return [BusinessConstant.BILLING_METHOD_MIXED_BILL_DEFAULT_PRODUCER, BusinessConstant.BILLING_METHOD_MIXED_BILL_FOR_THIS_POLICY,
            BusinessConstant.BILLING_METHOD_PRODUCER_BILL_DEFAULT_PRODUCER, BusinessConstant.BILLING_METHOD_PRODUCER_BILL_FOR_THIS_POLICY,
            BusinessConstant.BILLING_METHOD_PRODUCER_BILL_MANDATORY_DEFAULT_PRODUCER, BusinessConstant.BILLING_METHOD_PRODUCER_BILL_MANDATORY_FOR_THIS_POLICY];
    }

    static get PAYMENT_METHOD_SEPA() {
        return 'sepa';
    }

    static get PAYMENT_METHOD_BANK() {
        return 'bankTransfer';
    }

    static get BDP_ERROR_CODE() {
        return 620;
    }

    static get POLICY_DISPLAY_STATUS() {
        return 'In Force';
    }

    static get CANCELLATION_REASON_CODE() {
        return 'nottaken';
    }

    static get COMPANY_CODE() {
        return 'Company';
    }

    static get CANCELLATION_FLAT_REFUND_METHOD() {
        return 'flat';
    }

    static get VEHICLE_TYPE_TRANSPORT_ABOVE_35T() {
        return 'TransportAbove3dot5t_PV';
    }

    static get VEHICLE_TYPE_TRAILER_BELOW_35T() {
        return 'TrailerBelow3dot5T_PV';
    }

    static get VEHICLE_TYPE_TRAILER_ABOVE_35T() {
        return 'TrailerAbove3dot5T_PV';
    }

    static get VEHICLE_TYPE_TRAILER_CARAVAN() {
        return 'Caravan_PV';
    }

    static get VEHICLE_TYPE_OTHER_TRAILER() {
        return 'TrailerOther_PV';
    }

    static get INVOICE_PAID_STATUS_CANCELLED() {
        return 'Cancelled';
    }

    static get SYSTEM_OPTION_FIRST_RISK() {
        return 'First Risk';
    }

    static get PRIMARY_USE_POLICE_VEHICLE() {
        return 'PoliceVehicle_PV';
    }

    static get CONTACT_TYPE_PERSON() {
        return 'Person';
    }

    static get CONTACT_TYPE_COMPANY() {
        return 'Company';
    }

    static get JOB_TYPE_SUBMISSION() {
        return 'Submission';
    }

    static get JOB_TYPE_POLICY_CHANGE() {
        return 'PolicyChange';
    }

    static get JOB_TYPE_CANCELLATION() {
        return 'Cancellation';
    }

    static get PPV_VEHICLE_PAGE_POLICY_CHANGE() {
        return 'PPVVehicle';
    }

    static get PCV_VEHICLE_PAGE_POLICY_CHANGE() {
        return 'PCVVehicle';
    }

    static get PPV_VEHICLE_PAGE_NEW_SUBMISSION() {
        return 'ppvVehicleDetails';
    }

    static get PCV_VEHICLE_PAGE_NEW_SUBMISSION() {
        return 'pcvVehicleDetails';
    }

    static get VEHICLE_TYPE_VAN() {
        return 'Van_PV';
    }

    static get INVALID_ENTERPRISENUMBER() {
        return '0000000000';
    }

    static get PRIMARYPHONETYPE_WORK() {
        return 'work';
    }

    static get PRIMARYPHONETYPE_MOBILE() {
        return 'mobile';
    }

    static get DISCOUNT_MAY_NOT_EXCEED_HUNDRED_PERCENTAGE() {
        return 'Total discounts may not exceed 100%';
    }

    static get TYPELIST_SPECIAL_TAX_REGIME() {
        return 'SpecialTaxRegime_CxPV';
    }

    static get TYPELIST_SPECIAL_TAX_REGIME_NORMAL_TAX() {
        return 'normal_tax';
    }

    static get VEHICLE_TYPE_CLASSIC_VAN() {
        return 'ClassicVan_PV';
    }

    static get VEHICLE_TYPE_CLASSIC_MINIBUS() {
        return 'ClassicMinibus_PV';
    }

    static get VEHICLE_TYPE_MINIBUS() {
        return 'Minibus_PV';
    }

    static get VEHICLE_TYPE_CLASSIC_CAR() {
        return 'ClassicCar_PV';
    }

    static get SENDING_CHANNEL_MANDATE_FORM() {
        return 'attach_mandate_form';
    }

    static get LANGUAGE_TYPE() {
        return 'LanguageType';
    }

    static get DUTCH_AND_FRENCH_ONLY() {
        return 'dutchAndFrenchOnly_pv';
    }

    static get BRANCH_CODE_CUSTOM() {
        return 'CUSTOM';
    }
}

export default BusinessConstant;
